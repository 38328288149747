@import url("./components.css");
@import url("./utilities.css");
@import url("./tailwind_import_development.css");

html,
body {
  overflow: auto;
  height: 100%;
  color: var(--iom-text-color-a);
  font-size: var(--iom-font-size);
  background-color: var(--iom-background-color);
}

.success-color {
  color: #89c831;
}

.border-light-white {
  border-color: rgba(255 255 255 6%);
}

.filter-gray {
  @apply grayscale;

  filter: grayscale(100%);
}

.file #ebene3 {
  fill: var(--iom-file-background-color);
}

.file::after {
  content: attr(data-ext);
  position: absolute;
  top: 7px;
  width: 26px;
  text-align: center;
  left: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: white;
  font-size: smaller;
}

/* fix for file extension in outlook */
.file.outlook::after {
  top: 8px;
}

.file-shadow {
  filter: drop-shadow(0 4px 3px rgba(0 0 0 7%))
    drop-shadow(0 2px 2px rgba(0 0 0 6%));
}

/* somehow tailwind css class not working */
.light-font-fix {
  @apply font-light;
}

.svg-path-current-color {
  fill: currentcolor;
}

@media screen and (width <= 300px) {
  .hide-less-250px {
    display: none !important;
  }
}

.default-text {
  color: var(--iom-text-color-a);
}

.default-text-hover:hover {
  color: var(--iom-text-color-c);
}

.iom-textcolor-a {
  color: var(--iom-text-color-a);
}

.iom-textcolor-b {
  color: var(--iom-text-color-b);
}

.iom-textcolor-c {
  color: var(--iom-text-color-c);
}

.iom-textcolor-d {
  color: var(--iom-text-color-d);
}

.iom-textcolor-e {
  color: var(--iom-text-color-e);
}

.iom-border-color {
  border-color: var(--iom-border-color);
}

.iom-border-light-color {
  border-color: var(--iom-light-border-color);
}

.iom-placeholder-color::placeholder {
  color: var(--iom-text-color-c);
}

.iom-bg-color {
  background-color: var(--iom-background-color);
}

.iom-list-bg-color-hover:hover {
  background-color: var(--iom-background-color);
}

.iom-list-bg-color {
  background-color: var(--iom-conversation-list-background-color);
}

.iom-dropdown {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%237b7c7f'><polygon points='0,0 100,0 50,50'/></svg>");
  background-color: var(--iom-light-border-color);
  background-size: 12px;
  background-position: calc(100% - 2px) 8px;
  background-repeat: no-repeat;
  appearance: none; /* -webkit-appearance: none;
  -moz-appearance: none; */
  padding-left: 6px;
  padding-right: 20px;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  .iom-dropdown {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23a0a1a0'><polygon points='0,0 100,0 50,50'/></svg>");
  }
}
